/*
  Copyright 2018-2020 National Geographic Society
  Copyright 2021-2022 Impact Observatory

  Use of this software does not constitute endorsement by National Geographic
  Society (NGS). The NGS name and NGS logo may not be used for any purpose without
  written permission from NGS.

  Licensed under the Apache License, Version 2.0 (the "License"); you may not use
  this file except in compliance with the License. You may obtain a copy of the
  License at

      https://www.apache.org/licenses/LICENSE-2.0

  Unless required by applicable law or agreed to in writing, software distributed
  under the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
  CONDITIONS OF ANY KIND, either express or implied. See the License for the
  specific language governing permissions and limitations under the License.
*/

import yn from 'yn';

const NODE_ENV: string = process.env.NODE_ENV;
const PUBLIC_URL: string = process.env.GATSBY_APP_PUBLIC_URL || '/';
const AI_API_URL: string = process.env.GATSBY_APP_AI_API_URL || 'http://localhost:8081';
const APP_CLIENT: string = process.env.GATSBY_APP_CLIENT || 'io';
const PRIMARY_COLOR_LIGHT: string = process.env.GATSBY_APP_PRIMARY_COLOR_LIGHT;
const PRIMARY_COLOR_MAIN: string = process.env.GATSBY_APP_PRIMARY_COLOR_MAIN;
const PRIMARY_COLOR_DARK: string = process.env.GATSBY_APP_PRIMARY_COLOR_DARK;
const COMPANY_URL: string = process.env.GATSBY_APP_COMPANY_URL;
const COMPANY_ABOUT_URL: string = process.env.GATSBY_APP_COMPANY_ABOUT_URL;
const COMPANY_SUPPORT_URL: string = process.env.GATSBY_APP_COMPANY_SUPPORT_URL;
const POWERED_BY_PC_URL: string = process.env.GATSBY_APP_POWERED_BY_PC_URL;
const MAP_BASE_URL: string = process.env.GATSBY_APP_MAP_BASE_URL || '/';
const MAP_API_URL: string = process.env.GATSBY_APP_MAP_API_URL;
const MAP_CDN_URL: string = process.env.GATSBY_APP_MAP_CDN_URL;
const MAP_GRAYSCALE_STYLE: string = process.env.GATSBY_APP_MAP_GRAYSCALE_STYLE;
const MAP_SATELLITE_STYLE: string = process.env.GATSBY_APP_MAP_SATELLITE_STYLE;
const MAP_FULLPAGE_LICENSE: string = process.env.GATSBY_APP_MAP_FULLPAGE_LICENSE;
const MAP_TERMS_OF_USE_URL: string = process.env.GATSBY_APP_MAP_TERMS_OF_USE_URL;
const MAP_ADMIN_URL: string = process.env.GATSBY_APP_MAP_ADMIN_URL;
const MAP_APP_NAME: string = process.env.GATSBY_APP_MAP_NAME;
const MAP_MAPBOX_TOKEN: string = process.env.GATSBY_APP_MAP_MAPBOX_TOKEN;
const MAP_WEGLOT_API_KEY: string = process.env.GATSBY_APP_MAP_WEGLOT_API_KEY;
const MAP_EXTERNAL_IDP_URL: string = process.env.GATSBY_APP_MAP_EXTERNAL_IDP_URL || '';
const MAP_ENABLE_PUBLIC_ACCESS: boolean = yn(process.env.GATSBY_APP_MAP_ENABLE_PUBLIC_ACCESS, {
  default: false,
});
const MAP_SHOW_DISCLAIMER: boolean = yn(process.env.GATSBY_APP_MAP_SHOW_DISCLAIMER, {
  default: false,
});
const MAP_ENABLE_ELSA: boolean = yn(process.env.GATSBY_APP_MAP_ENABLE_ELSA, {
  default: false,
});

const MAP_SIDEBAR_WIDTH = 375;
const MAP_SIDEBAR_WIDTH_WIDE = 500;
const SUPER_ADMIN_WORKSPACE: string = '*';

export {
  NODE_ENV,
  PUBLIC_URL,
  AI_API_URL,
  APP_CLIENT,
  COMPANY_URL,
  COMPANY_ABOUT_URL,
  COMPANY_SUPPORT_URL,
  POWERED_BY_PC_URL,
  PRIMARY_COLOR_LIGHT,
  PRIMARY_COLOR_MAIN,
  PRIMARY_COLOR_DARK,
  MAP_BASE_URL,
  MAP_API_URL,
  MAP_CDN_URL,
  MAP_GRAYSCALE_STYLE,
  MAP_SATELLITE_STYLE,
  MAP_FULLPAGE_LICENSE,
  MAP_SHOW_DISCLAIMER,
  MAP_TERMS_OF_USE_URL,
  MAP_ADMIN_URL,
  MAP_APP_NAME,
  MAP_MAPBOX_TOKEN,
  MAP_WEGLOT_API_KEY,
  MAP_EXTERNAL_IDP_URL,
  MAP_ENABLE_PUBLIC_ACCESS,
  MAP_ENABLE_ELSA,
  MAP_SIDEBAR_WIDTH,
  MAP_SIDEBAR_WIDTH_WIDE,
  SUPER_ADMIN_WORKSPACE,
};
